import React, { useState, useEffect } from 'react';
import { FaSolarPanel, FaMapMarkerAlt, FaBolt } from 'react-icons/fa';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function PlantInformation() {
  const [plantInfo, setPlantInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchPlantInfo();
  }, []);

  const fetchPlantInfo = async () => {
    try {
      // Simulated API call
      const mockPlantInfo = {
        name: 'Solar Plant A',
        location: '123 Sun Street, Solar City, SC 12345',
        totalCapacity: '3000 W',
        panels: [
          {
            id: 1,
            capacity: '500 W',
            installationDate: '2022-05-15',
            panelType: 'Monocrystalline',
            inverterType: 'String Inverter',
            lastMaintenance: '2024-08-01',
            nextMaintenance: '2025-08-01',
          },
          {
            id: 2,
            capacity: '500 W',
            installationDate: '2022-05-15',
            panelType: 'Polycrystalline',
            inverterType: 'Microinverter',
            lastMaintenance: '2024-06-15',
            nextMaintenance: '2025-06-15',
          },
          {
            id: 3,
            capacity: '550 W',
            installationDate: '2022-06-01',
            panelType: 'Monocrystalline',
            inverterType: 'Power Optimizer',
            lastMaintenance: '2024-04-01',
            nextMaintenance: '2025-04-01',
          },
          {
            id: 4,
            capacity: '450 W',
            installationDate: '2022-05-20',
            panelType: 'Thin-film',
            inverterType: 'String Inverter',
            lastMaintenance: '2024-02-01',
            nextMaintenance: '2025-02-01',
          },
          {
            id: 5,
            capacity: '500 W',
            installationDate: '2022-05-25',
            panelType: 'Monocrystalline',
            inverterType: 'Microinverter',
            lastMaintenance: '2024-06-01',
            nextMaintenance: '2025-06-01',
          },
          {
            id: 6,
            capacity: '500 W',
            installationDate: '2022-05-30',
            panelType: 'Polycrystalline',
            inverterType: 'String Inverter',
            lastMaintenance: '2024-07-01',
            nextMaintenance: '2025-07-01',
          },
        ]
        
      };
      // Simulate API delay
      await new Promise(resolve => setTimeout(resolve, 1000));
      setPlantInfo(mockPlantInfo);
    } catch (error) {
      console.error('Error fetching plant info:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const calculateMaintenanceProgress = (lastMaintenance, nextMaintenance) => {
    const last = new Date(lastMaintenance);
    const next = new Date(nextMaintenance);
    const today = new Date();
    
    // If we're past the last maintenance date, adjust the calculation
    if (today > last) {
      const totalDuration = next - last;
      const elapsed = today - last;
      const progress = (elapsed / totalDuration) * 100;
      return Math.min(progress, 99).toFixed(0);
    } else {
      // If we're before the last maintenance date, return 0%
      return "0";
    }
  };

  const getProgressColor = (progress) => {
    if (progress < 30) return 'bg-green-500';
    if (progress < 60) return 'bg-yellow-500';
    return 'bg-red-500';
  };

  // Mock data for graphs
  const energyGenerationData = [
    { month: 'Jan', energy: 2400 },
    { month: 'Feb', energy: 2210 },
    { month: 'Mar', energy: 2290 },
    { month: 'Apr', energy: 2000 },
    { month: 'May', energy: 2181 },
    { month: 'Jun', energy: 2500 },
    { month: 'Jul', energy: 2100 },
  ];

  const combinedData = energyGenerationData.map((item, index) => {
    const panelCapacity = parseFloat(plantInfo?.panels[index % plantInfo.panels.length]?.capacity) || 500;
    return {
      month: item.month,
      energy: item.energy,
      revenue: item.energy * 0.15, // Assuming $0.15 per kWh
      efficiency: ((item.energy / (panelCapacity * 24 * 30)) * 100).toFixed(2), // Efficiency as a percentage of total possible energy generation
    };
  });

  const temperatureVsOutputData = [
    { temperature: 20, output: 1800 },
    { temperature: 25, output: 2000 },
    { temperature: 30, output: 2200 },
    { temperature: 35, output: 2100 },
    { temperature: 40, output: 1900 },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('default', { year: 'numeric', month: 'long' });
  };

  if (isLoading) return <div className="text-center py-8 text-tertiary">Loading...</div>;
  if (!plantInfo) return <div className="text-center py-8 text-red-500">Error loading plant information.</div>;

  return (
    <div className="container mx-auto px-4 py-8 solar-pattern">
      <h1 className="text-3xl font-bold mb-6 text-tertiary">Plant Information</h1>
      <p className="text-gray-600 mb-6">
        This page displays detailed information about the solar plant, including its location, total capacity, and individual panel specifications. Each panel's data is crucial for monitoring performance and scheduling maintenance.
      </p>
      <div className="bg-primary rounded-md shadow-md p-6">
        <h2 className="text-2xl font-semibold mb-4 text-secondary flex items-center">
          <FaSolarPanel className="mr-2" />
          {plantInfo.name}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
          <div className="bg-white rounded-md p-4 shadow">
            <p className="text-sm font-medium text-gray-500 flex items-center">
              <FaMapMarkerAlt className="mr-2 text-tertiary" />
              Location
            </p>
            <p className="mt-1 text-lg">{plantInfo.location}</p>
          </div>
          <div className="bg-white rounded-md p-4 shadow">
            <p className="text-sm font-medium text-gray-500 flex items-center">
              <FaBolt className="mr-2 text-tertiary" />
              Total Capacity
            </p>
            <p className="mt-1 text-lg">{plantInfo.totalCapacity}</p>
          </div>
        </div>
        <h3 className="text-xl font-semibold mb-4 text-secondary">Panel Information</h3>
        <p className="text-sm text-gray-500 mb-4">
          Below are the details for each solar panel in the plant. This information helps in tracking the performance and maintenance needs of individual panels.
        </p>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-3 gap-3">
          {plantInfo.panels.map((panel) => {
            const progress = calculateMaintenanceProgress(panel.lastMaintenance, panel.nextMaintenance);
            const progressColor = getProgressColor(progress);
            return (
              <div key={panel.id} className="bg-white rounded-md shadow p-3 border-l-4 border-accent text-xs">
                <h4 className="font-semibold mb-2 text-tertiary text-sm">Panel {panel.id}</h4>
                <div className="space-y-1">
                  <p><span className="font-medium text-gray-600">Capacity:</span> {panel.capacity}</p>
                  <p><span className="font-medium text-gray-600">Installed:</span> {panel.installationDate}</p>
                  <p><span className="font-medium text-gray-600">Type:</span> {panel.panelType}</p>
                  <p><span className="font-medium text-gray-600">Inverter:</span> {panel.inverterType}</p>
                  <p><span className="font-medium text-gray-600">Last Maintenance:</span> {formatDate(panel.lastMaintenance)}</p>
                  <p><span className="font-medium text-gray-600">Next Maintenance:</span> {formatDate(panel.nextMaintenance)}</p>
                  <div className="mt-2">
                    <p className="font-medium text-gray-600 mb-1">Maintenance Progress: {progress}%</p>
                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                      <div
                        className={`${progressColor} h-2.5 rounded-full`}
                        style={{ width: `${progress}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-white p-4 rounded-md shadow">
          <h3 className="text-xl font-semibold mb-4 text-secondary">Plant Performance Overview</h3>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={combinedData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" />
              <Tooltip formatter={(value, name) => {
                if (name === "Panel Efficiency") return `${value}%`;
                if (name === "Revenue") return `$${parseFloat(value).toFixed(2)}`;
                return value;
              }} />
              <Legend />
              <Line yAxisId="left" type="monotone" dataKey="energy" stroke="#8884d8" name="Energy (kWh)" />
              <Line yAxisId="right" type="monotone" dataKey="revenue" stroke="#82ca9d" name="Revenue" />
              <Line yAxisId="left" type="monotone" dataKey="efficiency" stroke="#ffc658" name="Panel Efficiency" />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="bg-white p-4 rounded-md shadow">
          <h3 className="text-xl font-semibold mb-4 text-secondary">Temperature vs. Energy Output</h3>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={temperatureVsOutputData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="temperature" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="output" stroke="#ff7300" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}

export default PlantInformation;
