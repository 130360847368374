import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaEnvelope, FaApple, FaGooglePlay, FaBars, FaTimes, FaSolarPanel, FaChartLine, FaLeaf } from 'react-icons/fa';

function Home() {
  const [scrollY, setScrollY] = useState(0);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const backgroundImageUrl = '/img/hero-image.jpg';

  // Sample data for the ROI graph
  const roiData = Array.from({ length: 16 }, (_, i) => ({
    year: i,
    roi: Math.round((Math.pow(1.076, i) - 1) * 100),
    cumulativeReturns: Math.round(90000 * Math.pow(1.076, i)),
    energyGenerated: Math.round(7000 * (i + 1))
  }));

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <div className="landing-page">
      <header className="fixed w-full z-50 bg-white bg-opacity-90 shadow-md transition-all duration-300" style={{ top: scrollY > 100 ? '-100%' : '0' }}>
        <div className="container mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center">
            <img src="/img/heliogains.svg" alt="Helio Gains Logo" className="h-6 w-auto mr-3" />
            <span className="text-lg font-semibold text-tertiary">HelioGains</span>
          </div>
          <nav className="hidden md:block">
            <ul className="flex space-x-6">
              <li><a href="#how-it-works" className="text-sm text-tertiary hover:text-secondary transition duration-300">How It Works</a></li>
              <li><a href="#investment-opportunities" className="text-sm text-tertiary hover:text-secondary transition duration-300">Invest</a></li>
              <li><a href="#sustainability-impact" className="text-sm text-tertiary hover:text-secondary transition duration-300">Impact</a></li>
              <li><Link to="/login" className="bg-secondary text-white text-sm px-4 py-2 rounded hover:bg-opacity-90 transition duration-300">Login</Link></li>
              <li><Link to="/admin-login" className="bg-tertiary text-white text-sm px-4 py-2 rounded hover:bg-opacity-90 transition duration-300">Admin</Link></li>
            </ul>
          </nav>
          <button className="md:hidden text-tertiary" onClick={toggleMobileMenu}>
            {mobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>
        {mobileMenuOpen && (
          <nav className="md:hidden bg-white">
            <ul className="flex flex-col items-center py-4">
              <li className="py-2"><a href="#how-it-works" className="text-tertiary hover:text-secondary transition duration-300" onClick={toggleMobileMenu}>How It Works</a></li>
              <li className="py-2"><a href="#investment-opportunities" className="text-tertiary hover:text-secondary transition duration-300" onClick={toggleMobileMenu}>Invest</a></li>
              <li className="py-2"><a href="#sustainability-impact" className="text-tertiary hover:text-secondary transition duration-300" onClick={toggleMobileMenu}>Impact</a></li>
              <li className="py-2"><Link to="/login" className="bg-secondary text-white px-4 py-2 rounded hover:bg-opacity-90 transition duration-300" onClick={toggleMobileMenu}>Login</Link></li>
              <li className="py-2"><Link to="/admin-login" className="bg-tertiary text-white px-4 py-2 rounded hover:bg-opacity-90 transition duration-300" onClick={toggleMobileMenu}>Admin</Link></li>
            </ul>
          </nav>
        )}
      </header>

      <section className="hero relative bg-cover bg-center h-[60vh] flex items-center justify-center overflow-hidden">
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="parallax-bg absolute inset-0 bg-cover bg-center" style={{
          backgroundImage: `url(${backgroundImageUrl})`,
          transform: `translateY(${scrollY * 0.5}px)`
        }}></div>
        <div className="container mx-auto px-4 z-10 text-center">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-semibold mb-4 text-white animate-fade-in-up">
            Invest in a Brighter, Greener Future
          </h1>
          <p className="text-lg sm:text-xl mb-8 lg:mb-12 text-white animate-fade-in-up animation-delay-300">
            Crowdfund solar projects, earn returns, and support renewable energy
          </p>
          <Link to="/signup" className="bg-secondary text-white text-sm font-medium py-2 px-6 rounded-md hover:bg-opacity-90 transition duration-300 animate-fade-in-up animation-delay-600 lg:mt-6">
            Start Investing Now
          </Link>
        </div>
      </section>

      <section className="py-12 bg-white">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-2xl md:text-3xl font-semibold mb-4 text-tertiary">
              Welcome to HelioGains
            </h2>
            <p className="text-base mb-6 text-gray-600">
              HelioGains is revolutionizing solar energy investment. We connect investors with high-potential solar projects, offering a unique opportunity to earn returns while contributing to a sustainable future.
            </p>
            <div className="flex justify-center space-x-4">
              <a href="#how-it-works" className="bg-secondary text-white text-sm px-5 py-2 rounded-md hover:bg-opacity-90 transition duration-300">
                Learn How It Works
              </a>
              <a href="#investment-opportunities" className="bg-tertiary text-white text-sm px-5 py-2 rounded-md hover:bg-opacity-90 transition duration-300">
                View Investment Options
              </a>
            </div>
          </div>
        </div>
      </section>

      <section id="how-it-works" className="py-12 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl md:text-3xl font-semibold text-center mb-8 text-tertiary">
            How Crowdfunding Solar Projects Works
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="text-center bg-primary p-4 rounded-md shadow-md">
              <div className="bg-secondary text-white rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                <FaSolarPanel className="text-2xl" />
              </div>
              <h3 className="text-lg font-medium mb-2 text-tertiary">Choose Your Investment</h3>
              <p className="text-sm text-gray-600">Select from solar panels for homes, factories, and more</p>
            </div>
            <div className="text-center bg-primary p-4 rounded-md shadow-md">
              <div className="bg-secondary text-white rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                <FaChartLine className="text-2xl" />
              </div>
              <h3 className="text-lg font-medium mb-2 text-tertiary">Track Your Returns</h3>
              <p className="text-sm text-gray-600">Monitor your investment performance through our app</p>
            </div>
            <div className="text-center bg-primary p-4 rounded-md shadow-md">
              <div className="bg-secondary text-white rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                <FaLeaf className="text-2xl" />
              </div>
              <h3 className="text-lg font-medium mb-2 text-tertiary">Make an Impact</h3>
              <p className="text-sm text-gray-600">Earn profits while supporting renewable energy</p>
            </div>
          </div>
        </div>

        <div className="mt-16">
            <h3 className="text-xl font-semibold text-center mb-8 text-tertiary">The Process</h3>
            <div className="max-w-7xl mx-auto bg-white p-6">
              <div className="flex justify-between items-center">
                <div className="text-center">
                  <div className="bg-secondary text-white rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-2">
                    <span className="text-xl font-bold">1</span>
                  </div>
                  <p className="text-sm">Choose</p>
                </div>
                <div className="flex-1 h-1 bg-secondary mx-2"></div>
                <div className="text-center">
                  <div className="bg-secondary text-white rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-2">
                    <span className="text-xl font-bold">2</span>
                  </div>
                  <p className="text-sm">Invest</p>
                </div>
                <div className="flex-1 h-1 bg-secondary mx-2"></div>
                <div className="text-center">
                  <div className="bg-secondary text-white rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-2">
                    <span className="text-xl font-bold">3</span>
                  </div>
                  <p className="text-sm">Track</p>
                </div>
                <div className="flex-1 h-1 bg-secondary mx-2"></div>
                <div className="text-center">
                  <div className="bg-secondary text-white rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-2">
                    <span className="text-xl font-bold">4</span>
                  </div>
                  <p className="text-sm">Profit</p>
                </div>
              </div>
            </div>
          </div>
      </section>

      <section id="investment-opportunities" className="py-12 bg-tertiary">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl md:text-3xl font-semibold text-center mb-8 text-white">
            Investment Paths
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                title: "Large-scale Solar Projects",
                description: "Invest in government-backed solar initiatives for stable, long-term returns.",
                features: ["High-impact projects", "Government partnerships", "Stable, long-term returns"],
                icon: "🏭"
              },
              {
                title: "Solar for Homes",
                description: "Support residential solar installations and empower homeowners to go green.",
                features: ["Community-focused projects", "Rapid deployment", "Direct impact on families"],
                icon: "🏡"
              },
              {
                title: "Industrial Solar Solutions",
                description: "Invest in solar panels for factories to drive industrial sustainability.",
                features: ["Large-scale impact", "Industrial partnerships", "Potential for high returns"],
                icon: "🏢"
              }
            ].map((path, index) => (
              <div key={index} className="bg-white rounded-md shadow-md overflow-hidden flex flex-col h-full transform transition duration-300 hover:scale-105">
                <div className="p-6 flex-grow">
                  <div className="text-4xl mb-4">{path.icon}</div>
                  <h3 className="text-lg font-medium mb-4 text-tertiary">{path.title}</h3>
                  <p className="text-sm text-gray-600 mb-4">{path.description}</p>
                  <ul className="text-sm text-gray-600 mb-4 list-disc list-inside">
                    {path.features.map((feature, i) => (
                      <li key={i}>{feature}</li>
                    ))}
                  </ul>
                </div>
                <div className="bg-secondary p-4 mt-auto">
                  <Link to="/login" className="block text-center text-white text-sm font-medium py-2 px-4 rounded hover:bg-opacity-90 transition duration-300">
                    Learn More
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section id="financial-gains" className="py-12 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl md:text-3xl font-semibold text-center mb-8 text-tertiary">
            Returns with HelioGains
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex flex-col h-full">
              <div className="flex-grow relative rounded-lg overflow-hidden shadow-md">
                <img 
                  src="/img/solar-home.jpg" 
                  alt="Solar Panels" 
                  className="absolute inset-0 w-full h-full object-cover"
                />
              </div>
            </div>
            <div className="flex flex-col h-full">
              <div className="bg-primary rounded-md shadow-md p-4 mb-6 flex-grow">
                <h3 className="text-lg font-medium mb-4 text-tertiary text-center">ROI Over 15 Years</h3>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={roiData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="year" />
                    <YAxis yAxisId="left" unit="%" />
                    <YAxis yAxisId="right" orientation="right" unit=" THB" />
                    <Tooltip formatter={(value, name) => {
                      if (name === "ROI") return `${value}%`;
                      if (name === "Cumulative Returns") return `${value.toLocaleString()} THB`;
                      if (name === "Energy Generated") return `${value.toLocaleString()} kWh`;
                      return value;
                    }} />
                    <Legend />
                    <Line yAxisId="left" type="monotone" dataKey="roi" stroke="#eab308" name="ROI" />
                    <Line yAxisId="right" type="monotone" dataKey="cumulativeReturns" stroke="#1e3c63" name="Cumulative Returns" />
                    <Line yAxisId="right" type="monotone" dataKey="energyGenerated" stroke="#82BF53" name="Energy Generated" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
              <p className="text-center text-sm text-tertiary">
                Watch your investment grow over time with HelioGains!
              </p>
              <div className="bg-white rounded-lg shadow-md p-6">
                <h3 className="text-lg font-medium mb-4 text-tertiary">Key Investment Details</h3>
                <ul className="space-y-2">
                  <li className="flex justify-between">
                    <span className="font-medium">Initial Investment:</span>
                    <span>90,000 THB for 5 kW</span>
                  </li>
                  <li className="flex justify-between">
                    <span className="font-medium">Annual Returns:</span>
                    <span>7.6% CAGR over 15 years</span>
                  </li>
                  <li className="flex justify-between">
                    <span className="font-medium">Break-even Point:</span>
                    <span>3.8 years</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="sustainability-impact" className="py-12 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl md:text-3xl font-semibold text-center mb-8 text-tertiary">
            Your Impact with HelioGains
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="text-center">
              <div className="bg-secondary text-white rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-3">
                <span className="text-2xl text-accent font-medium">CO₂</span>
              </div>
              <h3 className="text-lg font-medium mb-2 text-tertiary">Carbon Savings</h3>
              <p className="text-xl font-semibold text-secondary">1,500 tons</p>
              <p className="text-sm text-gray-600">of CO₂ emissions avoided</p>
            </div>
            <div className="text-center">
              <div className="bg-secondary text-white rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-3">
                <span className="text-2xl font-bold">⚡</span>
              </div>
              <h3 className="text-lg font-medium mb-2 text-tertiary">Renewable Energy</h3>
              <p className="text-xl font-semibold text-secondary">2.5 GWh</p>
              <p className="text-sm text-gray-600">of clean energy produced</p>
            </div>
            <div className="text-center">
              <div className="bg-secondary text-white rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-3">
                <span className="text-2xl font-bold">🏆</span>
              </div>
              <h3 className="text-lg font-medium mb-2 text-tertiary">Green Certifications</h3>
              <p className="text-xl font-semibold text-secondary">50+</p>
              <p className="text-sm text-gray-600">partners certified green</p>
            </div>
          </div>
        </div>
      </section>

      <footer className="bg-tertiary text-white py-10">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl font-semibold text-center mb-6">
            Get in Touch with HelioGains
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="text-center">
              <h3 className="text-lg font-medium mb-4">Connect With Us</h3>
              <div className="flex justify-center space-x-4">
                <a href="https://facebook.com/heliogains" target="_blank" rel="noopener noreferrer" className="hover:text-secondary">
                  <FaFacebook size={24} />
                </a>
                <a href="https://twitter.com/heliogains" target="_blank" rel="noopener noreferrer" className="hover:text-secondary">
                  <FaTwitter size={24} />
                </a>
                <a href="https://instagram.com/heliogains" target="_blank" rel="noopener noreferrer" className="hover:text-secondary">
                  <FaInstagram size={24} />
                </a>
                <a href="https://linkedin.com/company/heliogains" target="_blank" rel="noopener noreferrer" className="hover:text-secondary">
                  <FaLinkedin size={24} />
                </a>
              </div>
            </div>
            <div className="text-center">
              <h3 className="text-lg font-medium mb-4">Contact Us</h3>
              <p className="mb-2">
                <FaEnvelope className="inline-block mr-2" />
                Email: <a href="mailto:info@heliogains.com" className="hover:text-secondary">info@heliogains.com</a>
              </p>
              <p>Phone: <a href="tel:+1234567890" className="hover:text-secondary">+1234567890</a></p>
            </div>
            <div className="text-center">
              <h3 className="text-lg font-medium mb-4">Download Our App</h3>
              <div className="flex justify-center space-x-4">
                <a href="/" className="hover:text-secondary">
                  <FaApple size={24} />
                </a>
                <a href="/" className="hover:text-secondary">
                  <FaGooglePlay size={24} />
                </a>
              </div>
            </div>
          </div>
          <div className="mt-6 text-center text-xs">
            <p>&copy; 2023 HelioGains. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Home;
