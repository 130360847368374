import React, { useState, useEffect } from 'react';
import { FaSun, FaDollarSign, FaChartLine, FaLeaf, FaTree, FaHome, FaGasPump, FaCar, FaLightbulb, FaWater } from 'react-icons/fa';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

function InvestorDashboard() {
  const [investmentData, setInvestmentData] = useState({
    totalEnergyGenerated: 0,
    revenue: 0,
    projectedReturns: 0,
  });
  const [solarStats, setSolarStats] = useState({
    co2Reduced: 0,
    treesPlanted: 0,
    householdsPowered: 0,
    fuelSaved: 0,
    carsOffRoad: 0,
    waterSaved: 0,
    lightbulbsReplaced: 0,
    carbonFootprintReduced: 0,
  });

  useEffect(() => {
    // Simulating API call to fetch investor dashboard data
    const fetchInvestorData = async () => {
      // Replace this with actual API call
      const mockData = {
        totalEnergyGenerated: 500, // in kWh
        revenue: 1200, // in dollars
        projectedReturns: 1500, // in dollars
      };
      setInvestmentData(mockData);

      // Mock data for solar stats
      const mockSolarStats = {
        co2Reduced: 350, // in kg
        treesPlanted: 15,
        householdsPowered: 50,
        fuelSaved: 40, // in gallons
        carsOffRoad: 2,
        waterSaved: 1000, // in liters
        lightbulbsReplaced: 100,
        carbonFootprintReduced: 0.5, // in tons
      };
      setSolarStats(mockSolarStats);
    };

    fetchInvestorData();
  }, []);

  const mockGraphData = [
    { month: 'Jan', energy: 100, revenue: 220, projectedReturns: 250 },
    { month: 'Feb', energy: 120, revenue: 264, projectedReturns: 300 },
    { month: 'Mar', energy: 140, revenue: 308, projectedReturns: 350 },
    { month: 'Apr', energy: 160, revenue: 352, projectedReturns: 400 },
    { month: 'May', energy: 180, revenue: 396, projectedReturns: 450 },
    { month: 'Jun', energy: 200, revenue: 440, projectedReturns: 500 },
  ];

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-6 sm:py-8">
      <h1 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 text-tertiary">Dashboard</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="bg-secondary rounded-md shadow-md p-4 sm:p-6">
          <div className="flex items-center mb-2 sm:mb-4">
            <FaSun className="text-white text-lg sm:text-xl mr-2" />
            <h2 className="text-lg sm:text-xl font-semibold text-white">Total Energy Generated</h2>
          </div>
          <p className="text-2xl sm:text-4xl font-bold text-white">{investmentData.totalEnergyGenerated} kWh</p>
        </div>
        <div className="bg-tertiary rounded-md shadow-md p-4 sm:p-6">
          <div className="flex items-center mb-2 sm:mb-4">
            <FaDollarSign className="text-white text-lg sm:text-xl mr-2" />
            <h2 className="text-lg sm:text-xl font-semibold text-white">Revenue Earned</h2>
          </div>
          <p className="text-2xl sm:text-4xl font-bold text-white">${investmentData.revenue}</p>
        </div>
        <div className="bg-accent rounded-md shadow-md p-4 sm:p-6">
          <div className="flex items-center mb-2 sm:mb-4">
            <FaChartLine className="text-white text-lg sm:text-xl mr-2" />
            <h2 className="text-lg sm:text-xl font-semibold text-white">Projected Future Returns</h2>
          </div>
          <p className="text-2xl sm:text-4xl font-bold text-white">${investmentData.projectedReturns}</p>
        </div>
      </div>
      <div className="mt-6 sm:mt-8 bg-white p-4 sm:p-6 rounded-md shadow-md">
        <h2 className="text-lg sm:text-xl font-bold mb-3 sm:mb-4 text-tertiary">Investment Details</h2>
        <p className="text-sm sm:text-base text-gray-700 mb-4">
          Your investment has generated a total of {investmentData.totalEnergyGenerated} kWh of energy, resulting in ${investmentData.revenue} in revenue.
          Based on current trends, your projected future returns are estimated at ${investmentData.projectedReturns}.
        </p>
        <div className="mt-4 sm:mt-6 bg-primary rounded-md shadow-md p-2 sm:p-4">
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={mockGraphData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" />
              <Tooltip />
              <Legend />
              <Line yAxisId="left" type="monotone" dataKey="energy" stroke="#82BF53" name="Energy (kWh)" />
              <Line yAxisId="right" type="monotone" dataKey="revenue" stroke="#1e3c63" name="Revenue ($)" />
              <Line yAxisId="right" type="monotone" dataKey="projectedReturns" stroke="#eab308" name="Projected Returns ($)" />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="my-8 bg-white p-4 sm:p-6">
          <h2 className="text-center text-xl sm:text-2xl font-bold mb-4 sm:mb-6 text-tertiary">Your Solar Impact</h2>
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
            <div className="bg-green-100 p-4 rounded-md text-center">
              <div className="flex items-center justify-center mb-2 sm:mb-4 h-16">
                <FaLeaf className="text-green-600 w-10 h-10" />
              </div>
              <h2 className="text-lg sm:text-xl font-semibold text-green-600">CO2 Reduced</h2>
              <p className="text-2xl sm:text-4xl font-bold text-green-600">{solarStats.co2Reduced} kg</p>
              <p className="text-sm text-gray-600 mt-2">Equivalent to planting {solarStats.treesPlanted} trees</p>
            </div>
            <div className="bg-blue-100 p-4 rounded-md text-center">
              <div className="flex items-center justify-center mb-2 sm:mb-4 h-16">
                <FaTree className="text-blue-600 w-10 h-10" />
              </div>
              <h2 className="text-lg sm:text-xl font-semibold text-blue-600">Trees Planted</h2>
              <p className="text-2xl sm:text-4xl font-bold text-blue-600">{solarStats.treesPlanted}</p>
              <p className="text-sm text-gray-600 mt-2">Creating a mini-forest!</p>
            </div>
            <div className="bg-yellow-100 p-4 rounded-md text-center">
              <div className="flex items-center justify-center mb-2 sm:mb-4 h-16">
                <FaHome className="text-yellow-600 w-10 h-10" />
              </div>
              <h2 className="text-lg sm:text-xl font-semibold text-yellow-600">Homes Powered</h2>
              <p className="text-2xl sm:text-4xl font-bold text-yellow-600">{solarStats.householdsPowered}</p>
              <p className="text-sm text-gray-600 mt-2">That's a whole neighborhood!</p>
            </div>
            <div className="bg-orange-100 p-4 rounded-md text-center">
              <div className="flex items-center justify-center mb-2 sm:mb-4 h-16">
                <FaGasPump className="text-orange-600 w-10 h-10" />
              </div>
              <h2 className="text-lg sm:text-xl font-semibold text-orange-600">Fuel Saved</h2>
              <p className="text-2xl sm:text-4xl font-bold text-orange-600">{solarStats.fuelSaved} gal</p>
              <p className="text-sm text-gray-600 mt-2">Enough for {Math.round(solarStats.fuelSaved * 25)} miles of driving</p>
            </div>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mt-4">
            <div className="bg-purple-100 p-4 rounded-md text-center">
              <div className="flex items-center justify-center mb-2 sm:mb-4 h-16">
                <FaCar className="text-purple-600 w-10 h-10" />
              </div>
              <h2 className="text-lg sm:text-xl font-semibold text-purple-600">Cars Off Road</h2>
              <p className="text-2xl sm:text-4xl font-bold text-purple-600">{solarStats.carsOffRoad}</p>
              <p className="text-sm text-gray-600 mt-2">Equivalent to removing {solarStats.carsOffRoad} cars for a year</p>
            </div>
            <div className="bg-indigo-100 p-4 rounded-md text-center">
              <div className="flex items-center justify-center mb-2 sm:mb-4 h-16">
                <FaWater className="text-indigo-600 w-10 h-10" />
              </div>
              <h2 className="text-lg sm:text-xl font-semibold text-indigo-600">Water Saved</h2>
              <p className="text-2xl sm:text-4xl font-bold text-indigo-600">{solarStats.waterSaved} L</p>
              <p className="text-sm text-gray-600 mt-2">Enough to fill {Math.round(solarStats.waterSaved / 200)} bathtubs</p>
            </div>
            <div className="bg-pink-100 p-4 rounded-md text-center">
              <div className="flex items-center justify-center mb-2 sm:mb-4 h-16">
                <FaLightbulb className="text-pink-600 w-10 h-10" />
              </div>
              <h2 className="text-lg sm:text-xl font-semibold text-pink-600">LED Bulbs Equivalent</h2>
              <p className="text-2xl sm:text-4xl font-bold text-pink-600">{solarStats.lightbulbsReplaced}</p>
              <p className="text-sm text-gray-600 mt-2">Energy saved equals {solarStats.lightbulbsReplaced} LED bulbs</p>
            </div>
            <div className="bg-teal-100 p-4 rounded-md text-center">
              <div className="flex items-center justify-center mb-2 sm:mb-4 h-16">
                <FaLeaf className="text-teal-600 w-10 h-10" />
              </div>
              <h2 className="text-lg sm:text-xl font-semibold text-teal-600">Carbon Footprint Reduced</h2>
              <p className="text-2xl sm:text-4xl font-bold text-teal-600">{solarStats.carbonFootprintReduced} tons</p>
              <p className="text-sm text-gray-600 mt-2">Equivalent to {Math.round(solarStats.carbonFootprintReduced * 2)} round-trip flights</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvestorDashboard;