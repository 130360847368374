import React, { useContext, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AuthContext, AuthProvider } from './contexts/AuthContext';
import Navigation from './components/Navigation';
import Login from './components/Login';
import SignUp from './components/SignUp';
import HostDashboard from './components/HostDashboard';
import InvestorDashboard from './components/InvestorDashboard';
import AdminDashboard from './components/AdminDashboard';
import Home from './pages/Home';
import ProtectedRoute from './components/ProtectedRoute';
import PaymentManagement from './components/PaymentManagement';
import MeterReading from './components/MeterReading';
import Wallet from './components/Wallet';
import HostInvoices from './components/HostInvoices';
import PlantInformation from './components/PlantInformation';
import Usage from './components/Usage';
import AdminLogin from './components/AdminLogin';
import MeterReadingManagement from './components/MeterReadingManagement';
import UserManagement from './components/UserManagement';
import AdminPaymentProcessing from './components/AdminPaymentProcessing';
import MyInvestments from './components/MyInvestments';

function AppContent() {
  const { isAuthenticated } = useContext(AuthContext);
  const location = useLocation();

  const hideNavigation = ['/login', '/signup', '/', '/admin-login'].includes(location.pathname);

  useEffect(() => {
    if (isAuthenticated) {
      document.body.classList.add('logged-in');
    } else {
      document.body.classList.remove('logged-in');
    }
  }, [isAuthenticated]);

  return (
    <div className="h-screen bg-white flex flex-col">
      {isAuthenticated && !hideNavigation ? <Navigation /> : null}
      <div className="flex-grow">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/admin-login" element={<AdminLogin />} />
          <Route 
            path="/host-dashboard" 
            element={
              <ProtectedRoute allowedUserTypes={['host', 'admin']}>
                <HostDashboard />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/investor-dashboard" 
            element={
              <ProtectedRoute allowedUserTypes={['investor']}>
                <InvestorDashboard />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/admin-dashboard" 
            element={
              <ProtectedRoute allowedUserTypes={['admin']}>
                <AdminDashboard />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/payment-management" 
            element={
              <ProtectedRoute allowedUserTypes={['investor', 'admin']}>
                <PaymentManagement />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/meter-reading" 
            element={
              <ProtectedRoute allowedUserTypes={['host', 'admin']}>
                <MeterReading />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/wallet" 
            element={
              <ProtectedRoute allowedUserTypes={['investor']}>
                <Wallet />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/invoices" 
            element={
              <ProtectedRoute allowedUserTypes={['host', 'admin']}>
                <HostInvoices />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/plant-information" 
            element={
              <ProtectedRoute allowedUserTypes={['host', 'admin']}>
                <PlantInformation />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/usage" 
            element={
              <ProtectedRoute allowedUserTypes={['host', 'admin']}>
                <Usage />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/meter-reading-management" 
            element={<MeterReadingManagement />} 
          />
          <Route path="/user-management" element={<UserManagement />} />
          <Route path="/admin-payment-processing" element={<AdminPaymentProcessing />} />
          <Route 
            path="/my-investments" 
            element={
              <ProtectedRoute allowedUserTypes={['investor']}>
                <MyInvestments />
              </ProtectedRoute>
            } 
          />
          {/* ... other routes */}
        </Routes>
      </div>
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

export default App;