import React, { useState } from 'react';

function MeterReading() {
  const [reading, setReading] = useState('');
  const [date, setDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  });
  const [submitted, setSubmitted] = useState(false);

  // Updated mock data without cost
  const [history, setHistory] = useState([
    { id: 1, reading: 1000, date: '2023-05-01', consumption: 0, status: 'validated' },
    { id: 2, reading: 1200, date: '2023-06-01', consumption: 200, status: 'validated' },
    { id: 3, reading: 1400, date: '2023-07-01', consumption: 200, status: 'unvalidated' },
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const newReading = Number(reading);
    const lastReading = history[0]?.reading || 0;
    const consumption = newReading - lastReading;

    setHistory([
      { 
        id: history.length + 1, 
        reading: newReading, 
        date, 
        consumption
      },
      ...history
    ]);

    console.log('Submitted reading:', reading, 'for date:', date);
    setSubmitted(true);
    setReading('');
    setDate(() => {
      const today = new Date();
      return today.toISOString().split('T')[0];
    });
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-xl sm:text-2xl font-bold mb-6 text-tertiary">Meter Reading</h1>
      {submitted && (
        <div className="bg-secondary border border-green-400 text-white px-4 py-3 rounded relative mb-4" role="alert">
          <strong className="font-bold">Success!</strong>
          <span className="block sm:inline"> Meter reading submitted successfully.</span>
        </div>
      )}
      <form onSubmit={handleSubmit} className="mb-8">
        <div className="flex flex-col sm:flex-row sm:space-x-4">
          <div className="mb-4 sm:mb-0 sm:w-1/2">
            <label className="block text-tertiary text-sm font-bold mb-2" htmlFor="reading">
              Current Meter Reading (kWh)
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="reading"
              type="number"
              placeholder="Enter meter reading"
              value={reading}
              onChange={(e) => setReading(e.target.value)}
              required
            />
          </div>
          <div className="mb-4 sm:mb-0 sm:w-1/2">
            <label className="block text-tertiary text-sm font-bold mb-2" htmlFor="date">
              Date
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="date"
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="flex items-center justify-end mt-6">
          <button
            className="bg-secondary hover:bg-opacity-90 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
      
      <h2 className="text-lg font-semibold mb-4 text-tertiary">Reading History</h2>
      <div className="overflow-x-auto bg-primary rounded-md shadow-md">
        <table className="w-full text-left text-tertiary">
          <thead>
            <tr className="bg-gray-100">
              <th className="py-2 px-4 font-semibold">Date</th>
              <th className="py-2 px-4 font-semibold">Reading (kWh)</th>
              <th className="py-2 px-4 font-semibold">Consumption (kWh)</th>
              <th className="py-2 px-4 font-semibold">Status</th>
            </tr>
          </thead>
          <tbody>
            {history.map((item) => (
              <tr key={item.id} className="border-t border-gray-200">
                <td className="py-2 px-4">{item.date}</td>
                <td className="py-2 px-4">{item.reading}</td>
                <td className="py-2 px-4">{item.consumption}</td>
                <td className="py-2 px-4">{item.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      {/* Card view for small screens */}
      <div className="sm:hidden mt-4 space-y-4">
        {history.map((item) => (
          <div key={item.id} className="bg-white shadow rounded-md p-4">
            <div className="flex justify-between items-center mb-2">
              <span className="font-semibold">{item.date}</span>
              <span className={`px-2 py-1 rounded-full text-xs ${
                item.status === 'validated' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
              }`}>
                {item.status}
              </span>
            </div>
            <div className="text-sm text-gray-600">Reading: {item.reading} kWh</div>
            <div className="text-sm text-gray-600">Consumption: {item.consumption} kWh</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MeterReading;