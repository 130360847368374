import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    const storedAuth = localStorage.getItem('isAuthenticated');
    const storedUserType = localStorage.getItem('userType');
    if (storedAuth === 'true') {
      setIsAuthenticated(true);
      setUserType(storedUserType);
    }
  }, []);

  const login = async (email, password, type = 'user') => {
    // For admin login
    if (type === 'admin' && email === 'admin@heliogains.com' && password === 'admin123') {
      setIsAuthenticated(true);
      setUserType('admin');
      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('userType', 'admin');
      return 'admin';
    }

    // For regular user login
    const users = JSON.parse(localStorage.getItem('users') || '[]');
    const user = users.find(u => u.email === email && u.password === password);
    if (user) {
      setIsAuthenticated(true);
      setUserType(user.userType);
      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('userType', user.userType);
      return user.userType;
    } else {
      throw new Error('Invalid credentials');
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUserType(null);
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('userType');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, userType, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// We're not exporting AuthProvider as default, so we'll keep it as a named export
