import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import SimpleHeader from './SimpleHeader';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userType = await login(email, password);
      setWelcomeMessage(`Welcome back, ${email}!`);
      // Immediately hide the navigation and home page header
      document.body.classList.add('logged-in');
      // Navigate after a short delay to show the welcome message
      setTimeout(() => {
        switch(userType) {
          case 'host':
            navigate('/host-dashboard');
            break;
          case 'investor':
            navigate('/investor-dashboard');
            break;
          case 'admin':
            navigate('/admin-dashboard');
            break;
          default:
            navigate('/');
        }
      }, 2000);
    } catch (error) {
      console.error('Authentication error:', error);
      alert('Authentication failed. Please try again.');
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-cover bg-center" style={{ backgroundImage: "url('/img/solar-panels.jpg')" }}>
      <SimpleHeader />
      <div className="flex-grow flex items-center justify-center px-4 py-8">
        <div className="bg-primary bg-opacity-10 p-6 sm:p-8 rounded-md shadow-md w-full max-w-md">
          <h1 className="text-xl sm:text-2xl font-bold mb-4 text-center text-tertiary">Login to HelioGains</h1>
          {welcomeMessage && (
            <div className="bg-secondary border border-green-400 text-white px-4 py-3 rounded relative mb-4 text-sm sm:text-base">
              {welcomeMessage}
            </div>
          )}
          <form onSubmit={handleSubmit} className="space-y-4">
            <input
              type="email"
              placeholder="Email"
              className="w-full p-2 border rounded bg-white bg-opacity-80 text-gray-800 text-sm sm:text-base"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Password"
              className="w-full p-2 border rounded bg-white bg-opacity-80 text-gray-800 text-sm sm:text-base"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button className="w-full bg-tertiary text-white py-2 rounded hover:bg-opacity-70 text-sm sm:text-base" type="submit">
              Login
            </button>
          </form>
          <p className="mt-4 text-center text-white text-sm sm:text-base">
            <Link to="/forgot-password" className="text-accent hover:underline">
              Forgot Password?
            </Link>
          </p>
          <p className="mt-4 text-center text-white text-sm sm:text-base">
            Don't have an account? <Link to="/signup" className="text-accent hover:underline">Sign Up</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;