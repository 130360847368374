import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

function AdminPaymentProcessing() {
  const [pendingPayments, setPendingPayments] = useState([]);
  const [investorBankDetails, setInvestorBankDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statusFilter, setStatusFilter] = useState('all');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [showBankDetailsModal, setShowBankDetailsModal] = useState(false);
  const [selectedBankDetails, setSelectedBankDetails] = useState(null);

  useEffect(() => {
    fetchPendingPayments();
    fetchInvestorBankDetails();
  }, []);

  const fetchPendingPayments = async () => {
    try {
      setIsLoading(true);
      // Mock data - replace with actual API call
      const mockData = [
        { id: 1, investorId: 1, amount: 500, meterReadingId: 101, readingValidated: true },
        { id: 2, investorId: 2, amount: 750, meterReadingId: 102, readingValidated: false },
        { id: 3, investorId: 1, amount: 600, meterReadingId: 103, readingValidated: true },
      ];
      await new Promise(resolve => setTimeout(resolve, 500));
      setPendingPayments(mockData);
    } catch (err) {
      setError('Failed to fetch pending payments');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchInvestorBankDetails = async () => {
    try {
      setIsLoading(true);
      // Mock data - replace with actual API call
      const mockData = [
        { id: 1, investorId: 1, accountName: 'John Doe', accountNumber: '1234567890', bankName: 'Example Bank', ifscCode: 'EXMP0001234' },
        { id: 2, investorId: 2, accountName: 'Jane Smith', accountNumber: '0987654321', bankName: 'Sample Bank', ifscCode: 'SAMP0005678' },
      ];
      await new Promise(resolve => setTimeout(resolve, 500));
      setInvestorBankDetails(mockData);
    } catch (err) {
      setError('Failed to fetch investor bank details');
    } finally {
      setIsLoading(false);
    }
  };

  const handleProcessPayment = async (payment) => {
    setSelectedPayment(payment);
    setShowConfirmModal(true);
  };

  const confirmProcessPayment = async () => {
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 500));
      setPendingPayments(prevPayments => prevPayments.filter(payment => payment.id !== selectedPayment.id));
      setShowConfirmModal(false);
      toast.success('Payment processed successfully');
    } catch (err) {
      toast.error('Failed to process payment');
    }
  };

  const handleUpdateBankDetails = (details) => {
    setSelectedBankDetails(details);
    setShowBankDetailsModal(true);
  };

  const handleValidateBankDetails = async (details) => {
    setIsLoading(true);
    try {
      // Set status to pending immediately
      setInvestorBankDetails(prevDetails =>
        prevDetails.map(detail =>
          detail.id === details.id ? { ...detail, status: 'pending' } : detail
        )
      );

      // Simulated API call to validate bank details
      await new Promise(resolve => setTimeout(resolve, 2000)); // Simulate longer network delay

      // Simulate a successful validation
      const validatedDetails = { ...details, status: 'validated' };

      setInvestorBankDetails(prevDetails =>
        prevDetails.map(detail =>
          detail.id === validatedDetails.id ? validatedDetails : detail
        )
      );

      toast.success('Bank details validated successfully');
    } catch (error) {
      console.error('Error validating bank details:', error);
      toast.error('Failed to validate bank details. Please try again.');
      // Reset status to unvalidated if validation fails
      setInvestorBankDetails(prevDetails =>
        prevDetails.map(detail =>
          detail.id === details.id ? { ...detail, status: 'unvalidated' } : detail
        )
      );
    } finally {
      setIsLoading(false);
    }
  };

  const confirmUpdateBankDetails = async (updatedDetails) => {
    setIsLoading(true);
    try {
      // Simulate API call
      const response = await fetch(`/api/bank-details/${selectedBankDetails.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedDetails),
      });

      if (!response.ok) {
        throw new Error('Failed to update bank details');
      }

      const updatedDetail = await response.json();

      setInvestorBankDetails(prevDetails =>
        prevDetails.map(detail =>
          detail.id === selectedBankDetails.id ? { ...detail, ...updatedDetail, status: 'unvalidated' } : detail
        )
      );
      setShowBankDetailsModal(false);
      toast.success('Bank details updated successfully. Pending validation.');
    } catch (err) {
      console.error('Error updating bank details:', err);
      toast.error('Failed to update bank details. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const filteredPayments = pendingPayments.filter(payment => 
    statusFilter === 'all' || 
    (statusFilter === 'validated' && payment.readingValidated) ||
    (statusFilter === 'unvalidated' && !payment.readingValidated)
  );

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6 text-tertiary">Admin Payment Processing</h1>
      
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4 text-tertiary">Pending Payments</h2>
        <div className="mb-4">
          <label htmlFor="statusFilter" className="mr-2">Filter by status:</label>
          <select
            id="statusFilter"
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            className="border rounded px-2 py-1"
          >
            <option value="all">All</option>
            <option value="validated">Validated</option>
            <option value="unvalidated">Unvalidated</option>
          </select>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-2 px-4 text-left">Payment ID</th>
                <th className="py-2 px-4 text-left">Investor ID</th>
                <th className="py-2 px-4 text-left">Amount</th>
                <th className="py-2 px-4 text-left">Meter Reading ID</th>
                <th className="py-2 px-4 text-left">Reading Status</th>
                <th className="py-2 px-4 text-left">Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredPayments.map(payment => (
                <tr key={payment.id} className="border-t">
                  <td className="py-2 px-4">{payment.id}</td>
                  <td className="py-2 px-4">{payment.investorId}</td>
                  <td className="py-2 px-4">${payment.amount}</td>
                  <td className="py-2 px-4">{payment.meterReadingId}</td>
                  <td className="py-2 px-4">
                    <span className={`px-2 py-1 rounded ${payment.readingValidated ? 'bg-green-200 text-green-800' : 'bg-yellow-200 text-yellow-800'}`}>
                      {payment.readingValidated ? 'Validated' : 'Pending'}
                    </span>
                  </td>
                  <td className="py-2 px-4">
                    <button
                      onClick={() => handleProcessPayment(payment)}
                      className={`py-1 px-2 rounded transition-all duration-300 ${
                        payment.readingValidated
                          ? 'bg-secondary text-white hover:bg-opacity-90'
                          : 'bg-secondary bg-opacity-50 text-white cursor-not-allowed'
                      }`}
                      disabled={!payment.readingValidated}
                    >
                      Process
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      
      <div>
        <h2 className="text-xl font-semibold mb-4 text-tertiary">Investor Bank Details</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-2 px-4 text-left">Investor ID</th>
                <th className="py-2 px-4 text-left">Account Name</th>
                <th className="py-2 px-4 text-left">Account Number</th>
                <th className="py-2 px-4 text-left">Bank Name</th>
                <th className="py-2 px-4 text-left">IFSC Code</th>
                <th className="py-2 px-4 text-left">Status</th>
                <th className="py-2 px-4 text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              {investorBankDetails.map(detail => (
                <tr key={detail.id} className="border-t">
                  <td className="py-2 px-4">{detail.investorId}</td>
                  <td className="py-2 px-4">{detail.accountName}</td>
                  <td className="py-2 px-4">{detail.accountNumber}</td>
                  <td className="py-2 px-4">{detail.bankName}</td>
                  <td className="py-2 px-4">{detail.ifscCode}</td>
                  <td className="py-2 px-4">
                    <span className={`px-2 py-1 rounded ${
                      detail.status === 'validated' ? 'bg-green-200 text-green-800' : 
                      detail.status === 'pending' ? 'bg-yellow-200 text-yellow-800' : 
                      'bg-gray-200 text-gray-800'
                    }`}>
                      {detail.status || 'Unvalidated'}
                    </span>
                  </td>
                  <td className="py-2 px-4">
                    <button
                      onClick={() => handleUpdateBankDetails(detail)}
                      className="bg-tertiary text-white py-1 px-2 rounded hover:bg-opacity-90 mr-2"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleValidateBankDetails(detail)}
                      className={`py-1 px-2 rounded transition-all duration-300 ${
                        detail.status === 'validated' || detail.status === 'pending'
                          ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                          : 'bg-secondary text-white hover:bg-opacity-90'
                      }`}
                      disabled={detail.status === 'validated' || detail.status === 'pending'}
                    >
                      {detail.status === 'pending' ? 'Validating...' : 'Validate'}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {showConfirmModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg">
            <h3 className="text-lg font-semibold mb-4">Confirm Payment Processing</h3>
            <p>Are you sure you want to process the payment of ${selectedPayment.amount} for Investor ID {selectedPayment.investorId}?</p>
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => setShowConfirmModal(false)}
                className="mr-2 px-4 py-2 bg-gray-200 rounded"
              >
                Cancel
              </button>
              <button
                onClick={confirmProcessPayment}
                className="px-4 py-2 bg-secondary text-white rounded"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {showBankDetailsModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg">
            <h3 className="text-lg font-semibold mb-4">Update Bank Details</h3>
            <form onSubmit={(e) => {
              e.preventDefault();
              const formData = new FormData(e.target);
              const updatedDetails = Object.fromEntries(formData.entries());
              confirmUpdateBankDetails(updatedDetails);
            }}>
              <div className="mb-4">
                <label htmlFor="accountName" className="block mb-1">Account Name</label>
                <input
                  type="text"
                  id="accountName"
                  name="accountName"
                  defaultValue={selectedBankDetails.accountName}
                  className="w-full border rounded px-2 py-1"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="accountNumber" className="block mb-1">Account Number</label>
                <input
                  type="text"
                  id="accountNumber"
                  name="accountNumber"
                  defaultValue={selectedBankDetails.accountNumber}
                  className="w-full border rounded px-2 py-1"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="bankName" className="block mb-1">Bank Name</label>
                <input
                  type="text"
                  id="bankName"
                  name="bankName"
                  defaultValue={selectedBankDetails.bankName}
                  className="w-full border rounded px-2 py-1"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="ifscCode" className="block mb-1">IFSC Code</label>
                <input
                  type="text"
                  id="ifscCode"
                  name="ifscCode"
                  defaultValue={selectedBankDetails.ifscCode}
                  className="w-full border rounded px-2 py-1"
                  required
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setShowBankDetailsModal(false)}
                  className="mr-2 px-4 py-2 bg-gray-200 rounded"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-tertiary text-white rounded"
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminPaymentProcessing;
