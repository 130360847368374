import React from 'react';
import { Link } from 'react-router-dom';

function SimpleHeader() {
  return (
    <header className="bg-white bg-opacity-90 shadow-md">
      <div className="container mx-auto px-4 py-3 flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <img src="/img/heliogains.svg" alt="Helio Gains Logo" className="h-8 w-auto mr-2" />
          <span className="text-lg font-semibold text-tertiary">HelioGains</span>
        </Link>
      </div>
    </header>
  );
}

export default SimpleHeader;
