import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function Usage() {
  const [usageData, setUsageData] = useState([]);

  useEffect(() => {
    // Fetch usage data from API
    fetchUsageData();
  }, []);

  const fetchUsageData = async () => {
    // Simulated API call
    const mockUsageData = [
      { date: '2023-08-01', energy: 150 },
      { date: '2023-08-02', energy: 160 },
      { date: '2023-08-03', energy: 155 },
      { date: '2023-08-04', energy: 170 },
      { date: '2023-08-05', energy: 165 },
      { date: '2023-08-06', energy: 180 },
      { date: '2023-08-07', energy: 175 },
    ];
    setUsageData(mockUsageData);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6 text-tertiary">Energy Usage</h1>
      <div className="bg-primary rounded-md shadow-md p-6">
        <h2 className="text-xl font-semibold mb-4 text-secondary">Daily Energy Production</h2>
        <div style={{ height: '400px' }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={usageData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="energy" stroke="#82ca9d" name="Energy (kWh)" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}

export default Usage;
