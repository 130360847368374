import React, { useState, useEffect } from 'react';
import { FaSolarPanel, FaChartLine, FaDollarSign } from 'react-icons/fa';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

function MyInvestments() {
  const [investments, setInvestments] = useState([]);
  const [totalInvested, setTotalInvested] = useState(0);
  const [totalReturns, setTotalReturns] = useState(0);
  const [performanceData, setPerformanceData] = useState([]);

  useEffect(() => {
    // Simulating API call to fetch investment data
    const fetchInvestmentData = async () => {
      // Replace this with actual API call
      const mockInvestments = [
        { id: 1, project: 'Solar Farm A', amount: 5000, returns: 550, performance: 11 },
        { id: 2, project: 'Residential Solar B', amount: 3000, returns: 270, performance: 9 },
        { id: 3, project: 'Commercial Solar C', amount: 7000, returns: 840, performance: 12 },
      ];
      
      const mockPerformanceData = [
        { month: 'Jan', 'Solar Farm A': 100, 'Residential Solar B': 50, 'Commercial Solar C': 150 },
        { month: 'Feb', 'Solar Farm A': 120, 'Residential Solar B': 60, 'Commercial Solar C': 180 },
        { month: 'Mar', 'Solar Farm A': 140, 'Residential Solar B': 70, 'Commercial Solar C': 210 },
        { month: 'Apr', 'Solar Farm A': 160, 'Residential Solar B': 80, 'Commercial Solar C': 240 },
        { month: 'May', 'Solar Farm A': 180, 'Residential Solar B': 90, 'Commercial Solar C': 270 },
        { month: 'Jun', 'Solar Farm A': 200, 'Residential Solar B': 100, 'Commercial Solar C': 300 },
      ];

      setInvestments(mockInvestments);
      setTotalInvested(mockInvestments.reduce((sum, inv) => sum + inv.amount, 0));
      setTotalReturns(mockInvestments.reduce((sum, inv) => sum + inv.returns, 0));
      setPerformanceData(mockPerformanceData);
    };

    fetchInvestmentData();
  }, []);

  const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#a4de6c'];

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-2xl sm:text-3xl font-bold mb-6 text-tertiary">My Investments</h1>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
        <div className="bg-secondary rounded-md shadow-md p-4 sm:p-6">
          <div className="flex items-center mb-2 sm:mb-4">
            <FaSolarPanel className="text-white text-lg sm:text-xl mr-2" />
            <h2 className="text-lg sm:text-xl font-semibold text-white">Total Invested</h2>
          </div>
          <p className="text-2xl sm:text-4xl font-bold text-white">${totalInvested}</p>
        </div>
        <div className="bg-tertiary rounded-md shadow-md p-4 sm:p-6">
          <div className="flex items-center mb-2 sm:mb-4">
            <FaDollarSign className="text-white text-lg sm:text-xl mr-2" />
            <h2 className="text-lg sm:text-xl font-semibold text-white">Total Returns</h2>
          </div>
          <p className="text-2xl sm:text-4xl font-bold text-white">${totalReturns}</p>
        </div>
        <div className="bg-accent rounded-md shadow-md p-4 sm:p-6">
          <div className="flex items-center mb-2 sm:mb-4">
            <FaChartLine className="text-white text-lg sm:text-xl mr-2" />
            <h2 className="text-lg sm:text-xl font-semibold text-white">Average Performance</h2>
          </div>
          <p className="text-2xl sm:text-4xl font-bold text-white">
            {(totalReturns / totalInvested * 100).toFixed(2)}%
          </p>
        </div>
      </div>

      <div className="bg-primary rounded-md shadow-md p-4 sm:p-6 mb-8">
        <h2 className="text-xl font-semibold mb-4 text-tertiary">Investment Performance</h2>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={performanceData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend />
            {investments.map((investment, index) => (
              <Line 
                key={investment.id}
                type="monotone" 
                dataKey={investment.project} 
                stroke={colors[index % colors.length]} 
                name={investment.project}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className="bg-primary rounded-md shadow-md overflow-hidden">
        <h2 className="text-xl font-semibold p-4 sm:p-6 bg-secondary text-white">My Investment Projects</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Project</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount Invested</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Returns</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Performance</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {investments.map((investment) => (
                <tr key={investment.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{investment.project}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">${investment.amount}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">${investment.returns}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{investment.performance}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default MyInvestments;
