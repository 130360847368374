import React, { useState, useEffect } from 'react';
import { FaSun, FaDollarSign } from 'react-icons/fa';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function HostDashboard() {
  const [energyGenerated, setEnergyGenerated] = useState(0);
  const [revenue, setRevenue] = useState(0);
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    // Simulating API call to fetch dashboard data
    const fetchDashboardData = async () => {
      // Replace this with actual API call
      const mockData = {
        energyGenerated: 6000, // in kWh
        revenue: 14400, // in dollars
        graphData: [
          { month: 'Jan', energy: 450, revenue: 1080 },
          { month: 'Feb', energy: 480, revenue: 1152 },
          { month: 'Mar', energy: 520, revenue: 1248 },
          { month: 'Apr', energy: 550, revenue: 1320 },
          { month: 'May', energy: 600, revenue: 1440 },
          { month: 'Jun', energy: 620, revenue: 1488 },
          { month: 'Jul', energy: 650, revenue: 1560 },
          { month: 'Aug', energy: 630, revenue: 1512 },
          { month: 'Sep', energy: 580, revenue: 1392 },
          { month: 'Oct', energy: 520, revenue: 1248 },
          { month: 'Nov', energy: 480, revenue: 1152 },
          { month: 'Dec', energy: 420, revenue: 1008 },
        ],
      };
      setEnergyGenerated(mockData.energyGenerated);
      setRevenue(mockData.revenue);
      setGraphData(mockData.graphData);
    };

    fetchDashboardData();
  }, []);

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-2xl sm:text-3xl font-bold mb-6">Dashboard</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="bg-secondary rounded-md shadow-md p-4 sm:p-6">
          <div className="flex items-center mb-2 sm:mb-4">
            <FaSun className="text-yellow-400 text-lg sm:text-xl mr-2" />
            <h2 className="text-lg sm:text-xl font-semibold text-white">Total Energy Generated</h2>
          </div>
          <p className="text-2xl sm:text-4xl font-bold text-white">{energyGenerated} kWh</p>
        </div>
        <div className="bg-tertiary rounded-md shadow-md p-4 sm:p-6">
          <div className="flex items-center mb-2 sm:mb-4">
            <FaDollarSign className="text-green-500 text-lg sm:text-xl mr-2" />
            <h2 className="text-lg sm:text-xl font-semibold text-white">Revenue Earned</h2>
          </div>
          <p className="text-2xl sm:text-4xl font-bold text-white">${revenue}</p>
        </div>
      </div>
      <div className="mt-6 p-4 rounded-md bg-primary">
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={graphData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis yAxisId="left" />
            <YAxis yAxisId="right" orientation="right" />
            <Tooltip />
            <Legend />
            <Line yAxisId="left" type="monotone" dataKey="energy" stroke="#82BF53" name="Energy (kWh)" />
            <Line yAxisId="right" type="monotone" dataKey="revenue" stroke="#1e3c63" name="Revenue ($)" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default HostDashboard;