import React, { useState, useEffect } from 'react';
import { FaSolarPanel, FaUsers, FaMoneyBillWave, FaChartLine, } from 'react-icons/fa';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar, PieChart, Pie, Cell } from 'recharts';

function AdminDashboard() {
  const [dashboardData, setDashboardData] = useState({
    totalEnergyGenerated: 0,
    totalRevenue: 0,
    totalInvestors: 0,
    totalHouseholds: 0,
    projectTypes: [],
    monthlyEnergy: [],
    monthlyRevenue: [],
    investorGrowth: []
  });

  const [supplyVsDemandData, setSupplyVsDemandData] = useState([]);

  useEffect(() => {
    // Fetch dashboard data from API
    fetchDashboardData();
    setSupplyVsDemandData(calculateSupplyVsDemandData());
  }, []);

  const fetchDashboardData = async () => {
    // Simulated API call
    const mockData = {
      totalEnergyGenerated: 912500,
      totalRevenue: 2948125,
      totalInvestors: 500,
      totalHouseholds: 100,
      projectTypes: [
        { name: 'Large-scale', value: 40 },
        { name: 'Residential', value: 35 },
        { name: 'Industrial', value: 25 }
      ],
      monthlyEnergy: [
        { month: 'Jan', energy: 70000 },
        { month: 'Feb', energy: 75000 },
        { month: 'Mar', energy: 80000 },
        { month: 'Apr', energy: 85000 },
        { month: 'May', energy: 90000 },
        { month: 'Jun', energy: 95000 }
      ],
      monthlyRevenue: [
        { month: 'Jan', revenue: 226000 },
        { month: 'Feb', revenue: 242000 },
        { month: 'Mar', revenue: 258000 },
        { month: 'Apr', revenue: 274000 },
        { month: 'May', revenue: 290000 },
        { month: 'Jun', revenue: 306000 }
      ],
      investorGrowth: [
        { month: 'Jan', investors: 300 },
        { month: 'Feb', investors: 350 },
        { month: 'Mar', investors: 400 },
        { month: 'Apr', investors: 430 },
        { month: 'May', investors: 470 },
        { month: 'Jun', investors: 500 }
      ]
    };
    setDashboardData(mockData);
  };

  const calculateSupplyVsDemandData = () => {
    const energyPerKW = 1825; // kWh per year
    const kWPerHousehold = 5;
    const initialHouseholds = 100;
    const monthlyGrowthRate = 0.05; // 5% monthly growth

    // Seasonal factors for energy production (higher in summer, lower in winter)
    const seasonalFactors = [0.8, 0.9, 1.0, 1.1, 1.2, 1.3, 1.2, 1.1, 1.0, 0.9, 0.8, 0.7];

    // Random fluctuation for investor demand
    const getRandomFluctuation = () => 0.9 + Math.random() * 0.2; // Random factor between 0.9 and 1.1

    return Array.from({ length: 12 }, (_, index) => {
      const month = new Date(2023, index, 1).toLocaleString('default', { month: 'short' });
      const households = Math.round(initialHouseholds * Math.pow(1 + monthlyGrowthRate, index));
      
      // Apply seasonal factor to energy production
      const totalEnergy = households * kWPerHousehold * (energyPerKW / 12) * seasonalFactors[index];
      
      const baseRevenue = households * 29481.25 / 12;
      // Apply random fluctuation to investor demand
      const investorDemand = baseRevenue * 0.8 * getRandomFluctuation();

      return {
        month,
        supply: totalEnergy,
        demand: investorDemand
      };
    });
  };

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-6 text-tertiary">Dashboard</h1>
      
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 mb-8">
        <div className="bg-secondary text-white p-4 rounded-md">
          <div className="flex items-center mb-2">
            <FaSolarPanel className="text-2xl mr-2" />
            <h2 className="text-lg font-semibold">Total Energy Generated</h2>
          </div>
          <p className="text-2xl font-bold">{dashboardData.totalEnergyGenerated.toLocaleString()} kWh</p>
        </div>
        <div className="bg-tertiary text-white p-4 rounded-md">
          <div className="flex items-center mb-2">
            <FaMoneyBillWave className="text-2xl mr-2" />
            <h2 className="text-lg font-semibold">Total Revenue</h2>
          </div>
          <p className="text-2xl font-bold">{dashboardData.totalRevenue.toLocaleString()} THB</p>
        </div>
        <div className="bg-accent text-white p-4 rounded-md">
          <div className="flex items-center mb-2">
            <FaUsers className="text-2xl mr-2" />
            <h2 className="text-lg font-semibold">Total Investors</h2>
          </div>
          <p className="text-2xl font-bold">{dashboardData.totalInvestors.toLocaleString()}</p>
        </div>
        <div className="bg-primary text-tertiary p-4 rounded-md">
          <div className="flex items-center mb-2">
            <FaChartLine className="text-2xl mr-2" />
            <h2 className="text-lg font-semibold">Total Households</h2>
          </div>
          <p className="text-2xl font-bold">{dashboardData.totalHouseholds.toLocaleString()}</p>
        </div>
      </div>

      <div className="grid gap-8 grid-cols-1 mb-8">
        <div className="bg-white p-4 rounded-md shadow">
          <h2 className="text-xl font-semibold mb-4 text-tertiary">Supply vs Investor Balance</h2>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart 
              data={supplyVsDemandData} 
              margin={{ top: 20, right: 50, left: 20, bottom: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey="month" 
                label={{ value: 'Month', position: 'insideBottom', offset: -10 }}
              />
              <YAxis 
                yAxisId="left" 
                label={{ value: 'Energy (kWh)', angle: -90, position: 'insideLeft', offset: -5 }}
                tickFormatter={(value) => `${(value / 1000).toFixed(0)}k`}
              />
              <YAxis 
                yAxisId="right" 
                orientation="right" 
                label={{ value: 'Investor Demand (THB)', angle: 90, position: 'insideRight', offset: -15 }}
                tickFormatter={(value) => `${(value / 1000).toFixed(0)}k`}
              />
              <Tooltip 
                formatter={(value, name) => [
                  `${(value / 1000).toFixed(2)}k`, 
                  name.includes('Energy') ? 'kWh' : 'THB'
                ]}
              />
              <Legend verticalAlign="top" height={36}/>
              <Line yAxisId="left" type="monotone" dataKey="supply" stroke="#8884d8" name="Energy Supply (kWh)" />
              <Line yAxisId="right" type="monotone" dataKey="demand" stroke="#82ca9d" name="Investor Demand (THB)" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="grid gap-8 grid-cols-1 lg:grid-cols-2 mb-8">
        <div className="bg-white p-4 rounded-md shadow">
          <h2 className="text-xl font-semibold mb-4 text-tertiary">Monthly Energy Generation</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={dashboardData.monthlyEnergy}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="energy" stroke="#8884d8" name="Energy (kWh)" />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="bg-white p-4 rounded-md shadow">
          <h2 className="text-xl font-semibold mb-4 text-tertiary">Monthly Revenue</h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={dashboardData.monthlyRevenue}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="revenue" fill="#82ca9d" name="Revenue (THB)" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="grid gap-8 grid-cols-1 lg:grid-cols-2 mb-8">
        <div className="bg-white p-4 rounded-md shadow">
          <h2 className="text-xl font-semibold mb-4 text-tertiary">Investor Growth</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={dashboardData.investorGrowth}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="investors" stroke="#ffc658" name="Total Investors" />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="bg-white p-4 rounded-md shadow">
          <h2 className="text-xl font-semibold mb-4 text-tertiary">Project Types Distribution</h2>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={dashboardData.projectTypes}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
              >
                {dashboardData.projectTypes.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;