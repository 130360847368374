import React, { useState, useEffect } from 'react';
// Remove the DatePicker import

function UserManagement() {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({
    name: '',
    email: '',
    role: '',
    phone: '',
    dateOfBirth: ''
  });
  const [errors, setErrors] = useState({});
  const [editingUser, setEditingUser] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState('');

  useEffect(() => {
    // Fetch users from your API
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    // Simulated API call
    const mockUsers = [
      { id: 1, name: 'John Doe', email: 'john@example.com', role: 'host', phone: '1234567890', dateOfBirth: '1990-01-01' },
      { id: 2, name: 'Jane Smith', email: 'jane@example.com', role: 'investor', phone: '9876543210', dateOfBirth: '1985-05-15' },
      { id: 3, name: 'Admin User', email: 'admin@example.com', role: 'admin', phone: '5555555555', dateOfBirth: '1970-12-31' },
    ];
    setUsers(mockUsers);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      // Only allow numeric input for phone
      const numericValue = value.replace(/\D/g, '');
      setNewUser({ ...newUser, [name]: numericValue });
    } else {
      setNewUser({ ...newUser, [name]: value });
    }
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    // Simulated API call to add user
    const addedUser = { ...newUser, id: users.length + 1 };
    setUsers([...users, addedUser]);
    setNewUser({ name: '', email: '', role: '', phone: '', dateOfBirth: '' });
    setConfirmationMessage('User has been successfully created');
    setTimeout(() => setConfirmationMessage(''), 3000); // Clear message after 3 seconds
  };

  const handleEditUser = (user) => {
    setEditingUser(user);
    setNewUser(user);
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    // Simulated API call to update user
    const updatedUsers = users.map((user) =>
      user.id === editingUser.id ? newUser : user
    );
    setUsers(updatedUsers);
    setEditingUser(null);
    setNewUser({ name: '', email: '', role: '', phone: '', dateOfBirth: '' });
  };

  const handleDeleteUser = async (userId) => {
    // Simulated API call to delete user
    const updatedUsers = users.filter((user) => user.id !== userId);
    setUsers(updatedUsers);
  };

  const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

  return (
    <div className="container mx-auto px-4 py-6 sm:py-8">
      <h1 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 text-tertiary">User Management</h1>
      
      {confirmationMessage && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4" role="alert">
          <span className="block sm:inline">{confirmationMessage}</span>
        </div>
      )}
      
      {/* Add/Edit User Form */}
      <div className="rounded-md shadow-md p-4 sm:p-6 mb-6">
        <h2 className="text-lg font-semibold mb-4 text-tertiary">
          {editingUser ? 'Edit User' : 'Add New User'}
        </h2>
        <form onSubmit={editingUser ? handleUpdateUser : handleAddUser} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={newUser.name}
                onChange={handleInputChange}
                className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-secondary sm:text-sm transition duration-150 ease-in-out"
                required
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={newUser.email}
                onChange={handleInputChange}
                className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-secondary sm:text-sm transition duration-150 ease-in-out"
                required
              />
            </div>
            <div>
              <label htmlFor="role" className="block text-sm font-medium text-gray-700 mb-1">Role</label>
              <select
                id="role"
                name="role"
                value={newUser.role}
                onChange={handleInputChange}
                className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-secondary sm:text-sm transition duration-150 ease-in-out"
                required
              >
                <option value="">Select a role</option>
                <option value="host">Host</option>
                <option value="investor">Investor</option>
                <option value="admin">Admin</option>
              </select>
            </div>
            <div>
              <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">Phone Number</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={newUser.phone}
                onChange={handleInputChange}
                pattern="[0-9]*"
                inputMode="numeric"
                maxLength="10"
                className={`block w-full border ${errors.phone ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-secondary sm:text-sm transition duration-150 ease-in-out`}
              />
              {errors.phone && <p className="mt-1 text-sm text-red-500">{errors.phone}</p>}
            </div>
            <div>
              <label htmlFor="dateOfBirth" className="block text-sm font-medium text-gray-700 mb-1">Date of Birth</label>
              <input
                type="date"
                id="dateOfBirth"
                name="dateOfBirth"
                value={newUser.dateOfBirth}
                onChange={handleDateChange}
                max={today}
                className={`block w-full border ${errors.dateOfBirth ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-secondary sm:text-sm transition duration-150 ease-in-out`}
              />
              {errors.dateOfBirth && <p className="mt-1 text-sm text-red-500">{errors.dateOfBirth}</p>}
            </div>
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-secondary text-white py-2 px-4 rounded-md hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary transition duration-150 ease-in-out"
            >
              {editingUser ? 'Update User' : 'Add User'}
            </button>
          </div>
        </form>
      </div>
      
      {/* User List */}
      <div className="bg-primary rounded-md shadow-md p-4 sm:p-6">
        <h2 className="text-lg font-semibold mb-4 text-tertiary">User List</h2>
        
        {/* Table for larger screens */}
        <div className="hidden sm:block overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-100">
                <th className="text-left p-2">Name</th>
                <th className="text-left p-2">Email</th>
                <th className="text-left p-2">Role</th>
                <th className="text-left p-2">Phone</th>
                <th className="text-left p-2">Date of Birth</th>
                <th className="text-left p-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id} className="border-t border-gray-200">
                  <td className="p-2">{user.name}</td>
                  <td className="p-2">{user.email}</td>
                  <td className="p-2">{user.role}</td>
                  <td className="p-2">{user.phone}</td>
                  <td className="p-2">{user.dateOfBirth}</td>
                  <td className="p-2">
                    <button
                      onClick={() => handleEditUser(user)}
                      className="bg-tertiary text-white py-1 px-2 rounded-md hover:bg-opacity-90 mr-2 text-sm"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDeleteUser(user.id)}
                      className="bg-red-500 text-white py-1 px-2 rounded-md hover:bg-opacity-90 text-sm"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
        {/* Card view for small screens */}
        <div className="sm:hidden space-y-4">
          {users.map((user) => (
            <div key={user.id} className="bg-white p-4 rounded-md shadow">
              <div className="font-semibold">{user.name}</div>
              <div className="text-sm text-gray-600">{user.email}</div>
              <div className="text-sm text-gray-600 mb-2">{user.role}</div>
              <div className="text-sm text-gray-600 mb-2">{user.phone}</div>
              <div className="text-sm text-gray-600 mb-2">{user.dateOfBirth}</div>
              <div className="flex space-x-2">
                <button
                  onClick={() => handleEditUser(user)}
                  className="bg-tertiary text-white py-1 px-2 rounded-md hover:bg-opacity-90 text-sm flex-1"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDeleteUser(user.id)}
                  className="bg-red-500 text-white py-1 px-2 rounded-md hover:bg-opacity-90 text-sm flex-1"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default UserManagement;
